.AboutBanner {
  margin-bottom: 5em;
}
.content img {
  width: 5em;
}
.content h4 {
  font-size: 3.5em;
  line-height: 1.25em;
  font-weight: 500;
  margin: 0.5em 0em;
}
.content p {
  font-size: 1.15em;
  line-height: 1.5em;
}
@media screen and (max-width: 62em) {
  .content img {
    display: none;
  }
  .content h4 {
    font-size: 2.5em;
  }
}
