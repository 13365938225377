.HomePage {}

.ContactPanel {
    padding: 1em;
    margin-bottom: 1em;
    width: 95%;
    margin: 0 auto 1em;
    text-align: center;
}

.ContactDetails {
    width: 85%;
    margin: 0 auto 1.618em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContactDetails table {
    border-spacing: 1em; 
}

.ReserveTableButton {
    padding: 1.618em;
    border-radius: 2em;
    display: block;
    margin: 0 auto;
    width: 50%;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
}