.cardWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3em;
}
.card {
  margin: 1em;
  width: 20em;
  height: auto;
  padding: 1em;
}
.card div p {
  font-size: 1em;
}
.card div h4 {
  font-size: 1.25em;
}
.card img,
.card div div {
  width: 2.5em;
  height: 2.5em;
}
.IconWrapper {
  width: 1.618em;
  height: 1.618em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Icon {
  width: 1.25em;
  height: 1.25em;
}
