.AboutPage {}

.MobileMenuPanel {
    width: 80%;
    margin: 0 auto 1.618em;
    padding: 1.618em 1em;
    text-align: center;
}

/* Center the MenuCards in the MenuContainer */
.MenuContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.MenuImages {
    text-align: center;
}

.MenuImages img {
    width: 95%;
    margin: 0 auto;
}

.MenuCategory {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 0.618em;
}

.MenuCategory h3 {
    font-size: 2em;
}

.MenuItemsPanel {
    padding: 1em;
    text-align: center;
}

.MenuCard {
    display: flex;
    margin: 1em 0em;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
