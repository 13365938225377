.TemplateFooter {
    margin-bottom: 1.5em;
}

.FooterPanel {
    width: 97%;
    margin: 0 auto;
    margin-top: 1.5em;
}

.FooterContent {
    padding: 1em;
}

.FooterTitle {
    font-size: 2.5em;
    margin: 1em 0em 0em;
}

.FooterSubtitle {
    margin: 0 0 1em;
    font-size: 1.625em;
}

.BtnContainer {
    margin: 1.618em 0;
}

.BtnContainer a {
    background-color: rgba(0,0,0,0);
    cursor: pointer;
    border-radius: 2em;
    padding: 0.618em 1.618em;
    margin: 0 0.25em;
    text-decoration: none;
    display: inline-block;
    font-size: 1.34em;
}

.FooterNavLinks {
    display: flex;
    justify-content: space-between;
    padding: 0em 0 5em;
    width: 80%;
    margin: 0 auto;
}

.FooterNavColumn ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.FooterNavColumn li {
    margin-bottom: 0.618em;
}

.FooterNavLinks a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.FooterBranding {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    font-size: 0.8rem;
    position: relative;
}

.FooterBranding a {
    text-decoration: none;
}

.FooterLogoContainer {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.FooterIconWrapper {
  width: 1.618em;
  height: 1.618em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FooterIcon {
    width: 1em;
    height: 1em;
}

.socialIcons {
    display: flex;
    gap: 0.5em;
}
@media screen and (max-width: 62em) {
    .FooterNavLinks {
      display: block;
      padding-left: 2em;
    }
  }
@media screen and (max-width: 42em) {
    .FooterContent {
      padding: 1em 2em;
    }
    .FooterTitle {
      font-size: 1.5em;
      text-align: start;
      padding-left: 0.5em;
      margin: 1.25em 0 0.5em 0;
      font-weight: bold;
    }
    .BtnContainer {
      display: flex;
    }
    .BtnContainer a {
      padding: 0.618em 1em;
      font-size: 1.25em;
    }
    .FooterSubtitle {
      padding-left: 0.5em;
      margin: 0 0 1em;
      font-size: 1.25em;
      text-align: start;
    }
}
@media screen and (max-width: 32em) {
    .FooterBranding {
      padding: 2em;
      display: block;
    }
    .BtnContainer a {
      padding: 0.618em 1em;
      font-size: 1.1em;
    }
    .FooterLogoContainer {
      left: 2.5em;
      top: 10%;
    }
}