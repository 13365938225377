.mapContainer {
  width: 97%;
  margin: 0 auto;
}
.mapContainer iframe {
  width: 100%;
  height: 30em;
  border-radius: 1em;
}

@media screen and (max-width: 48em) {
  .mapContainer iframe {
    height: 20em;
  }
}
