.HomeBanner {
  margin-bottom: 16em;
}
.InfoContainer h1 {
  font-size: 3.5em;
  line-height: 1.25em;
  font-weight: 500;
  margin: 0.5em 0em;
}
.InfoContainer p {
  font-size: 1.15em;
  line-height: 1.5em;
}

.ButtonContainer a {
  cursor: pointer;
  border-radius: 2em;
  padding: 0.618em 1.618em;
  margin: 0 0.25em;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
}

.wave {
  position: absolute;
  margin-top: -0.1875em;
}
@media screen and (max-width: 70em) {
  .ButtonContainer a {
    padding: 0.518em 1.218em;
    font-size: 0.9em;
  }
}
@media screen and (max-width: 62em) {
  .HomeBanner {
    margin-bottom: 15em;
  }

  .InfoContainer h1 {
    font-size: 1.75em;
  }

  .InfoContainer p {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 35em) {
  .ButtonContainer a {
    padding: 0.518em 1.218em;
    font-size: 0.7em;
  }

  .InfoContainer h1 {
    font-size: 1.5em;
  }

  .InfoContainer p {
    font-size: 0.8em;
  }
}
