.NotFoundPage {}

.PanelContainer {
    padding: 0.618em 1.618em 6.18em;
    width: 100%;
}

.GoHomeButton {
    display: inline-block;
    width: 8.5em;
    padding: 1em 0.35em;
    border: none;
    border-radius: 2em;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    margin-top: 1.618em;
}

.NotFoundImage {
    width: 100% !important;
    height: auto;
    margin: 0 auto;
    display: block;
}

/* Smaller Image on Mobile */
@media screen and (max-width: 62em) {
    .NotFoundImage {
        width: 10em !important;
    }
}